/* home.css - Styles specific to the home.js page */

/* General Styles */
.home-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.dvd-logo {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transition: transform 0.5s ease-in-out;
}

.hr {
  height: 2em;
  border: none;
  background-color: var(--darker);
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--dark);
  color: var(--text-color);
  font-size: 1.8rem;
}

.scrollable-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 2em;
  background-color: var(--darkest);
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .scrollable-content {
    padding: 1em;
  }
}

/* Header Styles */
.header-bar {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.4em;
  padding-bottom: 0;
  background-color: var(--darkest);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid var(--darker);
  gap: 1em;
  z-index: 100;
  position: relative; /* Ensures the header takes up only as much space as it needs */
}

@media (max-width: 768px) {
  .header-bar {
    flex-direction: column; /* Stack header items vertically on small screens */
    align-items: center;
  }
}

.tabs {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 1em;
  height: 100%;
}

@media (min-width: 768px) {
  .tabs {
    justify-content: flex-start;
  }

  .tabs:first-child {
    justify-content: flex-start;
  }

  .tabs:last-child {
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .tabs {
    width: 100%;
    justify-content: center;
  }
}

.separator {
  display: flex;
  height: 4px;
  width: 80%;
  background-color: var(--dark);
}

@media (min-width: 768px) {
  .separator {
    width: 1em;
  }
}

/* Tab Styles */
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  color: var(--button-text-color);
  padding: 10px;
  border: 1px solid var(--button-border);
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab.active {
  background: var(--dark);
  color: var(--weird);
  border-color: var(--yellow);
  z-index: 12;
  transform: scale(1, 1.10);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab:hover {
  background: var(--darker);
  transform: scale(1, 1.10);
}

@media (max-width: 768px) {
  .tab {
    flex: 1;
    margin: 0;
    text-align: center;
  }

  .tab-content {
    padding: 0.2em;
  }
}

.tab-icon {
  margin-right: 8px;
}

/* Ranking Section Styles */
.ranking-section,
.histogram-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  background: var(--darker);
  border: 1px solid var(--button-border);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .ranking-section,
  .histogram-section {
    padding-bottom: 4em;
  }
}

/* Scrollbar Styling */
.scrollable-content::-webkit-scrollbar {
  width: 12px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 6px;
  filter: brightness(0.8);
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}
