/* Container that holds the entire map and panel section */
.map-and-panel {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  background-color: var(--darkest);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

hr {
  height: 2px;
  border: none;
  background-color: var(--grey);
}

/* World map container styling */
.world-map-container {
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 1em;
  transition: width 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .world-map-container.sidebar-open {
    display: none; /* Hide map container when the sidebar is open */
  }
}

/* Side panel styling */
.side-panel {
  position: relative;
  width: 300px;
  height: 100%;
  background-color: var(--darker);
  color: var(--text-color);
  box-shadow: var(--box-shadow);
  padding: 1em;
  overflow: hidden; /* Remove scroll from the entire panel */
  transition: transform 0.3s ease-in-out;
  border-left: 1px solid var(--border-color);
  z-index: 15;
  display: flex;
  flex-direction: column; /* Align elements vertically */
}

@media (max-width: 768px) {
  .side-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
}

/* Close button styling */
.close-button {
  background-color: var(--dark);
  border: none;
  color: var(--lightest);
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1em; /* Add margin below the button */
  z-index: 20; /* Ensure button stays on top */
  flex-shrink: 0; /* Prevent close button from resizing */
}

.close-button:hover {
  background-color: var(--dark);
}

/* Accordion item styling */
.accordion-item-wrapper {
  margin-bottom: 15px;
  border-radius: 5px;
}

.accordion-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: var(--button-background);
  color: var(--heading-color);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  overflow: hidden;
}

.accordion-item-header strong {
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis for truncated content */
  white-space: nowrap; /* Prevents wrapping */
}

.accordion-icon {
  color: var(--weird);
  font-weight: bold;
}

.accordion-item-header:hover {
  background-color: var(--dark);
}

.accordion-item-details {
  padding: 0.5em;
  color: var(--text-color);
  border-top: 1px solid var(--dark);
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.accordion-item-details strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.accordion-expanded-details {
  padding: 0.5em;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  background-color: var(--dark);
  gap: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  white-space: nowrap;
}

.accordion-expanded-details strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.accordion-item-row {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Accordion container with scrolling */
.accordion-container {
  overflow-y: auto;
  flex: 1; /* Allow accordion to take the remaining height */
}

/* Scrollbar Styling */
.accordion-container::-webkit-scrollbar {
  width: 12px;
}

.accordion-container::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

.accordion-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 6px;
  filter: brightness(0.8);
}

.accordion-container::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

.item-key {
  font-weight: normal;
  flex: 1;
  text-align: left;
}

.item-value {
  flex: 2;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}

.accordion-section-title {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--heading-color);
}

/* Map controls styling */
.controls {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none; /* Allows clicking through other parts of the map */
}

.search-bar {
  width: 100%;
  max-width: 600px;
  padding: 0.75rem;
  font-size: 1rem;
  margin-left: 1em;
  margin-right: 1em;
  background-color: var(--darker);
  color: var(--lightest);
  border: 2px solid var(--dark);
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
  pointer-events: auto; /* Ensures the search bar is interactive */
}

.search-bar:focus {
  border-color: var(--blue);
}

.search-error {
  border-color: var(--red);
}

.search-error:focus {
  border-color: var(--orange);
}

@media (max-width: 768px) {
  .search-bar {
    width: calc(100% - 1em);
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}


/* Custom pin styling */
.custom-pin {
  transition: transform 0.2s ease;
  cursor: pointer;
  border-radius: 50%;
  border: 6px solid var(--darkest);
  text-align: center; 
  color: var(--darkest);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Make the pin position absolute */
  transform: translate(-50%, -50%); /* Center the pin */
}

.custom-pin:hover {
  transform: translate(-50%, -50%) scale(1.1); /* Keep centered while scaling */
}

/* Probe origin pin styling */
.probe-pin {
  transition: transform 0.2s ease;
  cursor: pointer;
  background-color: var(--blue);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 6px solid var(--darkest);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Make the pin position absolute */
  transform: translate(-50%, -50%); /* Center the pin */
  color: var(--darkest);
}

.probe-pin:hover {
  transform: translate(-50%, -50%) scale(1.1); /* Keep centered while scaling */
}
