/* Updated histogram chart and bar styling */
.histogram {
  border-radius: 8px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  background-color: var(--darker);
  margin: 20px 0;
  color: var(--text-color);
  width: 100%;
}

.histogram h2 {
  margin-bottom: 15px;
  color: var(--accent-color);
  font-size: 1.2rem;
}

/* Histogram Chart */
.histogram-chart {
  display: flex;
  justify-content: space-evenly; /* Ensures bars are spaced evenly */
  align-items: flex-end;
  height: 200px;
  padding: 10px;
  background-color: var(--dark);
  border-radius: 8px;
  position: relative;
  width: 100%;
  flex-wrap: nowrap; /* Prevents wrapping */
}

.histogram-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 5px; /* Adds spacing between bars */
  padding-top: 5px;
  background-color: var(--grey);
  height: 100%;
  border-radius: 4px;
  justify-content: flex-end;
}

.histogram-bar:hover .bar {
  background-color: var(--accent-color);
}

.histogram-bar:hover .tooltip {
  display: flex;
  flex-direction: column;
}

.bar {
  background-color: var(--darker);
  width: 80%;
  border-top-left-radius: 0.2em;
  border-top-right-radius: 0.2em;
  position: relative;
  transition: all 0.3s ease;
}

/* Summary Visualization */
.summary-visualization {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 80px; /* Increased height for clarity */
  background-color: var(--dark);
  border-radius: 8px;
  padding: 10px;
}

.summary-bar {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* Interquartile Range Background */
.iqr-background {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: var(--darker);
  transition: all 0.3s ease;
  z-index: 2;
  border-radius: 4px;
}

.range {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--button-background);
  height: 100%;
  z-index: 1;
  border-radius: 8px;
}

.median-label {
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .median-label {
    transform: translate(-50%, 20px);
  }
}

.hoverable-line {
  position: absolute;
  height: 100%;
  width: 20px; /* Wider hoverable area */
  top: 0;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.hoverable-line:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -0.4em);
  background-color: var(--accent-color);
  color: var(--section-background);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 3;
}

.median,
.percentile {
  position: absolute;
  background-color: var(--accent-color);
  width: 5px;
  height: 100%;
}

.median {
  z-index: 3;
}

.percentile {
  background-color: var(--dark);
}

.percentile:hover {
  background-color: var(--accent-color);
}

.summary-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.summary-labels span {
  text-align: center;
}

.leftAlign {
  transform: translateX(-50%);
}

.rightAlign {
  transform: translateX(50%);
}

/* Responsive X-axis label handling */
.histogram-x-axis {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.x-axis-label {
  text-align: center;
  color: var(--text-color);
  font-size: 0.8rem;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .x-axis-label:nth-child(odd) {
    display: none; /* Hide every other label on small screens */
  }
}
